/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "crmcloud-core";
import * as i2 from "@angular/common";
import * as i3 from "@ngx-translate/core";
import * as i4 from "../../node_modules/ngx-toastr/ngx-toastr.ngfactory";
import * as i5 from "ngx-toastr";
import * as i6 from "@markpieszak/ng-application-insights/dist/src/app-insight.service";
import * as i7 from "@markpieszak/ng-application-insights/dist/index";
import * as i8 from "@angular/common/http";
var ConfigurationModuleNgFactory = i0.ɵcmf(i1.ConfigurationModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(1073742336, i1.ConfigurationModule, i1.ConfigurationModule, [])]); });
export { ConfigurationModuleNgFactory as ConfigurationModuleNgFactory };
var SpinnerModuleNgFactory = i0.ɵcmf(i1.SpinnerModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, [LoadingSpinnerComponentNgFactory]], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i2.NgLocalization, i2.NgLocaleLocalization, [i0.LOCALE_ID, [2, i2.ɵangular_packages_common_common_a]]), i0.ɵmpd(1073742336, i2.CommonModule, i2.CommonModule, []), i0.ɵmpd(1073742336, i1.SpinnerModule, i1.SpinnerModule, [])]); });
export { SpinnerModuleNgFactory as SpinnerModuleNgFactory };
var TranslationModuleNgFactory = i0.ɵcmf(i1.TranslationModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i0.LOCALE_ID, i1.ɵa, [i3.TranslateService]), i0.ɵmpd(4608, i2.NgLocalization, i2.NgLocaleLocalization, [i0.LOCALE_ID, [2, i2.ɵangular_packages_common_common_a]]), i0.ɵmpd(1073742336, i2.CommonModule, i2.CommonModule, []), i0.ɵmpd(1073742336, i3.TranslateModule, i3.TranslateModule, []), i0.ɵmpd(1073742336, i1.TranslationModule, i1.TranslationModule, [])]); });
export { TranslationModuleNgFactory as TranslationModuleNgFactory };
var NotificationModuleNgFactory = i0.ɵcmf(i1.NotificationModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, [i4.ToastNgFactory]], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i2.NgLocalization, i2.NgLocaleLocalization, [i0.LOCALE_ID, [2, i2.ɵangular_packages_common_common_a]]), i0.ɵmpd(1073742336, i2.CommonModule, i2.CommonModule, []), i0.ɵmpd(1073742336, i5.ToastrModule, i5.ToastrModule, []), i0.ɵmpd(1073742336, i1.NotificationModule, i1.NotificationModule, []), i0.ɵmpd(256, i5.TOAST_CONFIG, { default: i5.DefaultGlobalConfig, config: {} }, [])]); });
export { NotificationModuleNgFactory as NotificationModuleNgFactory };
var PipesModuleNgFactory = i0.ɵcmf(i1.PipesModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i2.NgLocalization, i2.NgLocaleLocalization, [i0.LOCALE_ID, [2, i2.ɵangular_packages_common_common_a]]), i0.ɵmpd(1073742336, i2.CommonModule, i2.CommonModule, []), i0.ɵmpd(1073742336, i1.PipesModule, i1.PipesModule, [])]); });
export { PipesModuleNgFactory as PipesModuleNgFactory };
var LoggerModuleNgFactory = i0.ɵcmf(i1.LoggerModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i2.NgLocalization, i2.NgLocaleLocalization, [i0.LOCALE_ID, [2, i2.ɵangular_packages_common_common_a]]), i0.ɵmpd(1073742336, i2.CommonModule, i2.CommonModule, []), i0.ɵmpd(256, i6.AppInsightsConfig, { instrumentationKeySetLater: true }, []), i0.ɵmpd(512, i6.AppInsightsService, i6.AppInsightsService, [[2, i6.AppInsightsConfig], i0.Injector]), i0.ɵmpd(1073742336, i7.ApplicationInsightsModule, i7.ApplicationInsightsModule, [[3, i7.ApplicationInsightsModule], i6.AppInsightsService]), i0.ɵmpd(1073742336, i1.LoggerModule, i1.LoggerModule, [])]); });
export { LoggerModuleNgFactory as LoggerModuleNgFactory };
var CommonModuleNgFactory = i0.ɵcmf(i1.CommonModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i1.WindowRef, i1.BrowserWindowRef, []), i0.ɵmpd(5120, i1.WINDOW, i1.windowFactory, [i1.WindowRef, i0.PLATFORM_ID]), i0.ɵmpd(4608, i1.WindowPrintService, i1.WindowPrintService, [i1.WINDOW]), i0.ɵmpd(4608, i1.WindowService, i1.WindowService, [i1.WINDOW]), i0.ɵmpd(4608, i1.WindowScrollService, i1.WindowScrollService, [i1.WINDOW]), i0.ɵmpd(1073742336, i1.CommonModule, i1.CommonModule, [])]); });
export { CommonModuleNgFactory as CommonModuleNgFactory };
var DictionariesModuleNgFactory = i0.ɵcmf(i1.DictionariesModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i2.NgLocalization, i2.NgLocaleLocalization, [i0.LOCALE_ID, [2, i2.ɵangular_packages_common_common_a]]), i0.ɵmpd(5120, i1.DICTIONARIES_SERVICES, function (p0_0, p0_1, p1_0, p1_1, p2_0, p2_1, p3_0, p3_1, p4_0, p4_1, p5_0, p5_1, p6_0, p6_1, p7_0, p7_1) { return [new i1.ɵd(p0_0, p0_1), new i1.ɵf(p1_0, p1_1), new i1.ɵg(p2_0, p2_1), new i1.ɵh(p3_0, p3_1), new i1.ɵi(p4_0, p4_1), new i1.ɵj(p5_0, p5_1), new i1.ɵk(p6_0, p6_1), new i1.ɵl(p7_0, p7_1)]; }, [i8.HttpClient, i1.AppConfigurationService, i8.HttpClient, i1.AppConfigurationService, i8.HttpClient, i1.AppConfigurationService, i8.HttpClient, i1.AppConfigurationService, i8.HttpClient, i1.AppConfigurationService, i8.HttpClient, i1.AppConfigurationService, i8.HttpClient, i1.AppConfigurationService, i8.HttpClient, i1.AppConfigurationService]), i0.ɵmpd(4608, i1.DictionariesService, i1.DictionariesService, [i1.DICTIONARIES_SERVICES]), i0.ɵmpd(1073742336, i2.CommonModule, i2.CommonModule, []), i0.ɵmpd(1073742336, i1.ConfigurationModule, i1.ConfigurationModule, []), i0.ɵmpd(1073742336, i1.DictionariesModule, i1.DictionariesModule, [])]); });
export { DictionariesModuleNgFactory as DictionariesModuleNgFactory };
var GeolocationModuleNgFactory = i0.ɵcmf(i1.GeolocationModule, [], function (_l) { return i0.ɵmod([i0.ɵmpd(512, i0.ComponentFactoryResolver, i0.ɵCodegenComponentFactoryResolver, [[8, []], [3, i0.ComponentFactoryResolver], i0.NgModuleRef]), i0.ɵmpd(4608, i1.GeolocationService, i1.GeolocationService, [i1.WINDOW]), i0.ɵmpd(1073742336, i1.GeolocationModule, i1.GeolocationModule, [])]); });
export { GeolocationModuleNgFactory as GeolocationModuleNgFactory };
var styles_LoadingSpinnerComponent = [".backdrop[_ngcontent-%COMP%]{opacity:.5;position:fixed;top:0;left:0;right:0;bottom:0;z-index:2000}.wrapper[_ngcontent-%COMP%]{position:fixed;z-index:2001;top:50%;left:50%;transform:translate(-50%,-50%);max-height:2em}"];
var RenderType_LoadingSpinnerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_LoadingSpinnerComponent, data: {} });
export { RenderType_LoadingSpinnerComponent as RenderType_LoadingSpinnerComponent };
function View_LoadingSpinnerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "div", [["class", "backdrop spinner-backdrop"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "div", [["class", "circle-spinner"]], null, null, null, null, null))], null, null); }
export function View_LoadingSpinnerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_LoadingSpinnerComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵncd(null, 0)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSpinner; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_LoadingSpinnerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-loading-spinner", [], null, null, null, View_LoadingSpinnerComponent_0, RenderType_LoadingSpinnerComponent)), i0.ɵdid(1, 49152, null, 0, i1.LoadingSpinnerComponent, [], null, null)], null, null); }
var LoadingSpinnerComponentNgFactory = i0.ɵccf("app-loading-spinner", i1.LoadingSpinnerComponent, View_LoadingSpinnerComponent_Host_0, { showSpinner: "showSpinner" }, {}, ["*"]);
export { LoadingSpinnerComponentNgFactory as LoadingSpinnerComponentNgFactory };
