import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { EMPTY, from, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { AuthProxyService } from './auth-proxy.service';
import { AuthStorageService } from './auth-storage.service';
import { AuthStoreService } from './auth-store.service';
import { ClientAuthError } from 'msal';
import { LOGIN_TYPE_STORAGE_KEY } from '../models/auth-login-type.enum';
import { ExternalLoginProviderType } from '../models/external-login-provider-type.enum';
import * as i0 from "@angular/core";
import * as i1 from "./auth-storage.service";
import * as i2 from "./auth-proxy.service";
import * as i3 from "./auth-store.service";
import * as i4 from "@angular/router";
import * as i5 from "@azure/msal-angular";
var CRM_TOKEN = 'agricrm';
var AuthService = /** @class */ (function () {
    function AuthService(storageService, authProxyService, authStoreService, router, msalAuthService) {
        this.storageService = storageService;
        this.authProxyService = authProxyService;
        this.authStoreService = authStoreService;
        this.router = router;
        this.msalAuthService = msalAuthService;
    }
    AuthService.prototype.login = function (userCredentials) {
        var _this = this;
        return this.authProxyService.token(userCredentials).pipe(switchMap(function (res) { return _this.handleTokenResponse(res.accessToken, res.refreshToken); }));
    };
    AuthService.prototype.doSocialLogin = function (loginType) {
        var _this = this;
        switch (loginType) {
            case ExternalLoginProviderType.MICROSOFT: {
                return from(this.msalAuthService
                    .loginPopup({
                    prompt: 'select_account',
                })
                    .catch(function (e) {
                    if (e instanceof ClientAuthError && e.errorCode === 'user_cancelled') {
                        return false;
                    }
                    throw e;
                })).pipe(filter(function (res) { return res !== false; }), switchMap(function (res) { return _this.loginSocial(res, loginType); }), switchMap(function (res) { return _this.handleTokenResponse(res.accessToken, res.refreshToken); }), map(function () { return true; }));
            }
        }
        throw new Error('Not supported external login type ' + loginType);
    };
    AuthService.prototype.logout = function () {
        var _this = this;
        var loginType;
        return this.storageService.getItem(LOGIN_TYPE_STORAGE_KEY).pipe(tap(function (res) { return (loginType = res); }), tap(function () { return _this.authStoreService.updateUserNotLogged(); }), catchError(function (err) {
            console.log('Error occured during logout ' + err);
            return of(true);
        }), mergeMap(function (res) {
            _this.router.navigate(['auth', 'login'], { queryParams: { loginType: loginType } });
            return EMPTY;
        }));
    };
    AuthService.prototype.register$ = function (input) {
        return this.authProxyService.register(input);
    };
    AuthService.prototype.remindPassword = function (email, hostname) {
        return this.authProxyService.remindPassword({
            email: email,
            host: 'FoodPass',
            hostName: hostname,
            logoUrl: '/assets/images/agricrm_logo.png',
        });
    };
    AuthService.prototype.resetPassword = function (data) {
        return this.authProxyService.resetPassword(data);
    };
    AuthService.prototype.confirmEmail = function (code) {
        return this.authProxyService.confirmEmail(code);
    };
    AuthService.prototype.sendEmailActivationLink$ = function (input) {
        return this.authProxyService.sendEmailActivationLink$({
            email: input.email,
            host: CRM_TOKEN,
        });
    };
    AuthService.prototype.loginSocial = function (res, provider) {
        return this.authProxyService.tokenSocial(res, provider);
    };
    AuthService.prototype.clearLoginData = function () {
        this.authStoreService.updateUserNotLogged();
    };
    AuthService.prototype.handleTokenResponse = function (accessToken, refreshToken) {
        var _this = this;
        return this.authStoreService.updateAuthTokens(accessToken, refreshToken).pipe(switchMap(function () { return _this.authProxyService.getUserProfile(); }), switchMap(function (userProfile) { return _this.authStoreService.updateUserData(userProfile); }), map(function () { return null; }));
    };
    AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.AuthStorageService), i0.ɵɵinject(i2.AuthProxyService), i0.ɵɵinject(i3.AuthStoreService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.MsalService)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}());
export { AuthService };
