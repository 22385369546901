import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthStoreService } from '@crm-portal/core/auth/services/auth-store.service';
import { AppConfigurationService, NotificationService } from 'crmcloud-core';
import { jwtDecode } from 'jwt-decode';
import { EMPTY, of, ReplaySubject, throwError } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { AuthProxyService } from './auth-proxy.service';
import * as i0 from "@angular/core";
import * as i1 from "./auth-proxy.service";
import * as i2 from "./auth-store.service";
import * as i3 from "crmcloud-core";
import * as i4 from "@angular/router";
var AGRICRM_TOKEN = 'agricrm';
var AuthInterceptorService = /** @class */ (function () {
    function AuthInterceptorService(authProxyService, authStoreService, config, router, notificationService) {
        this.authProxyService = authProxyService;
        this.authStoreService = authStoreService;
        this.config = config;
        this.router = router;
        this.notificationService = notificationService;
        this.AUTH_HEADER = 'Authorization';
        this.refreshInProgress = false;
    }
    AuthInterceptorService.prototype.intercept = function (req, next) {
        var _this = this;
        if (!req.url.includes(this.config.configuration.api_url)) {
            return next.handle(req);
        }
        if (!this.shouldIntercept(req)) {
            return next.handle(req);
        }
        var token = this.authStoreService.getAccessToken;
        if (!token) {
            return next.handle(req);
        }
        if (this.isJwtInvalid(token)) {
            return this.refreshAccessToken().pipe(switchMap(function (newToken) {
                if (!newToken) {
                    _this.handleSessionExpired();
                    return EMPTY;
                }
                return next.handle(_this.addToken(req, newToken));
            }));
        }
        var request = this.addToken(req, token);
        return next.handle(request).pipe(catchError(function (error) {
            // Request is unauthorized - logout
            if (error && error.status === 401) {
                _this.handleSessionExpired();
                return of(null);
            }
            return throwError(error);
        }));
    };
    AuthInterceptorService.prototype.addToken = function (request, token) {
        return request.clone({
            headers: request.headers.set(this.AUTH_HEADER, "Bearer " + token),
        });
    };
    AuthInterceptorService.prototype.isJwtInvalid = function (token) {
        var tokenExpirationDate = this.getTokenExpirationDate(token);
        if (!tokenExpirationDate) {
            return true;
        }
        var currentDate = new Date().getTime() / AuthInterceptorService.SecondsToMsRation;
        return currentDate >= tokenExpirationDate;
    };
    AuthInterceptorService.prototype.getTokenExpirationDate = function (token) {
        var decoded = jwtDecode(token);
        if (decoded.exp === undefined) {
            return null;
        }
        return decoded.exp;
    };
    AuthInterceptorService.prototype.refreshAccessToken = function () {
        var _this = this;
        if (this.refreshInProgress) {
            return this.refreshSubject;
        }
        var accessToken = this.authStoreService.getAccessToken;
        if (!accessToken) {
            this.handleSessionExpired();
            return of(null);
        }
        var refreshToken = this.authStoreService.getRefreshToken;
        if (!refreshToken) {
            this.handleSessionExpired();
            return of(null);
        }
        var refreshTokenInput = {
            accessToken: accessToken,
            refreshToken: refreshToken,
            host: AGRICRM_TOKEN,
        };
        this.refreshSubject = new ReplaySubject();
        this.refreshInProgress = true;
        return this.authProxyService.refreshToken(refreshTokenInput).pipe(mergeMap(function (data) {
            return _this.authStoreService.updateAuthTokens(data.accessToken, data.refreshToken).pipe(map(function () {
                _this.refreshSubject.next(data.accessToken);
                _this.refreshSubject.complete();
                _this.refreshInProgress = false;
                return data.accessToken;
            }));
        }), catchError(function (err) {
            console.error(err);
            _this.refreshInProgress = false;
            _this.handleSessionExpired();
            return of(err);
        }));
    };
    AuthInterceptorService.prototype.handleSessionExpired = function () {
        var _this = this;
        this.authStoreService.updateUserNotLogged();
        return this.router.navigate(['auth', 'login']).then(function () {
            _this.notificationService.info("AUTH.logoutError:message");
        });
    };
    AuthInterceptorService.prototype.shouldIntercept = function (req) {
        return !req.headers.has('X-Skip-Auth');
    };
    AuthInterceptorService.SecondsToMsRation = 1000;
    AuthInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthInterceptorService_Factory() { return new AuthInterceptorService(i0.ɵɵinject(i1.AuthProxyService), i0.ɵɵinject(i2.AuthStoreService), i0.ɵɵinject(i3.AppConfigurationService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i3.NotificationService)); }, token: AuthInterceptorService, providedIn: "root" });
    return AuthInterceptorService;
}());
export { AuthInterceptorService };
export var AuthInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true,
};
