import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PipesModule, SpinnerModule, TranslationModule } from 'crmcloud-core';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { MessageCoreServicesModule } from '@crm-portal/core/message/message-core-services.module';
import { SidebarComponent } from '@crm-portal/core/layout/sidebar/sidebar.component';
import { LangSelectorComponent } from '@crm-portal/core/layout/navbar/lang-selector/lang-selector.component';
import { MessageUnreadNotificationComponent } from '@crm-portal/core/layout/navbar/message-unread-notification/components/message-unread-notification.component';
import { NavbarComponent } from '@crm-portal/core/layout/navbar/navbar.component';
import { UserNavItemComponent } from '@crm-portal/core/layout/navbar/user-nav-item/user-nav-item.component';
import { SidebarLinkDirective } from '@crm-portal/core/layout/directives/sidebarlink.directive';
import { SidebarDirective } from '@crm-portal/core/layout/directives/sidebar.directive';
import { SidebarAnchorToggleDirective } from '@crm-portal/core/layout/directives/sidebaranchortoggle.directive';
import { SidebarToggleDirective } from '@crm-portal/core/layout/directives/sidebartoggle.directive';
import { SidebarListDirective } from '@crm-portal/core/layout/directives/sidebarlist.directive';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [
    FullLayoutComponent,
    SidebarComponent,
    LangSelectorComponent,
    MessageUnreadNotificationComponent,
    NavbarComponent,
    UserNavItemComponent,
    SidebarLinkDirective,
    SidebarDirective,
    SidebarAnchorToggleDirective,
    SidebarToggleDirective,
    SidebarListDirective,
  ],
  imports: [
    CommonModule,
    SpinnerModule,
    RouterModule,
    TranslationModule,
    MessageCoreServicesModule,
    PerfectScrollbarModule,
    NgbModule,
    PipesModule,
  ],
  exports: [
    FullLayoutComponent,
    SidebarComponent,
    LangSelectorComponent,
    MessageUnreadNotificationComponent,
    NavbarComponent,
    UserNavItemComponent,
  ],
})
export class LayoutModule {}
