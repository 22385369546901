import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { MsalService } from '@azure/msal-angular';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import { AuthProxyService } from './auth-proxy.service';
import { AuthStorageService } from './auth-storage.service';
import { AuthStoreService } from './auth-store.service';

import { ClientAuthError } from 'msal';
import { LOGIN_TYPE_STORAGE_KEY } from '../models/auth-login-type.enum';
import { ConfirmEmailInputDto } from '../models/confirm-email-input.model';
import { ExternalLoginProviderType } from '../models/external-login-provider-type.enum';
import { PartnerRegisterDto } from '../models/partner-register.dto';
import { RemindPasswordDto } from '../models/remind-password.dto';
import { ResetPasswordDto } from '../models/reset-password.dto';
import { SendEmailActivationLinkInputDto } from '../models/send-email-activation-link-input.model';
import { TokenAuthResultDto } from '../models/token-auth-result.dto';
import { UserCredentials } from '../models/user-credentials.dto';

const CRM_TOKEN = 'agricrm';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private storageService: AuthStorageService,
    private authProxyService: AuthProxyService,
    private authStoreService: AuthStoreService,
    private router: Router,
    private msalAuthService: MsalService,
  ) {}

  public login(userCredentials: UserCredentials): Observable<void> {
    return this.authProxyService.token(userCredentials).pipe(switchMap(res => this.handleTokenResponse(res.accessToken, res.refreshToken)));
  }

  public doSocialLogin(loginType: ExternalLoginProviderType): Observable<boolean> {
    switch (loginType) {
      case ExternalLoginProviderType.MICROSOFT: {
        return from(
          this.msalAuthService
            .loginPopup({
              prompt: 'select_account',
            })
            .catch(e => {
              if (e instanceof ClientAuthError && e.errorCode === 'user_cancelled') {
                return false;
              }

              throw e;
            }),
        ).pipe(
          filter(res => res !== false),
          switchMap(res => this.loginSocial(res, loginType)),
          switchMap(res => this.handleTokenResponse(res.accessToken, res.refreshToken)),
          map(() => true),
        );
      }
    }

    throw new Error('Not supported external login type ' + loginType);
  }

  public logout(): Observable<any> {
    let loginType;
    return this.storageService.getItem(LOGIN_TYPE_STORAGE_KEY).pipe(
      tap(res => (loginType = res)),
      tap(() => this.authStoreService.updateUserNotLogged()),
      catchError(err => {
        console.log('Error occured during logout ' + err);
        return of(true);
      }),
      mergeMap(res => {
        this.router.navigate(['auth', 'login'], { queryParams: { loginType } });
        return EMPTY;
      }),
    );
  }

  public register$(input: PartnerRegisterDto): Observable<any> {
    return this.authProxyService.register(input);
  }

  public remindPassword(email: string, hostname: string): Observable<any> {
    return this.authProxyService.remindPassword({
      email,
      host: 'FoodPass',
      hostName: hostname,
      logoUrl: '/assets/images/agricrm_logo.png',
    } as RemindPasswordDto);
  }

  public resetPassword(data: ResetPasswordDto) {
    return this.authProxyService.resetPassword(data);
  }

  public confirmEmail(code: ConfirmEmailInputDto) {
    return this.authProxyService.confirmEmail(code);
  }

  public sendEmailActivationLink$(input: SendEmailActivationLinkInputDto) {
    return this.authProxyService.sendEmailActivationLink$({
      email: input.email,
      host: CRM_TOKEN,
    } as SendEmailActivationLinkInputDto);
  }

  private loginSocial(res: any, provider: ExternalLoginProviderType): Observable<TokenAuthResultDto> {
    return this.authProxyService.tokenSocial(res, provider);
  }

  public clearLoginData() {
    this.authStoreService.updateUserNotLogged();
  }

  private handleTokenResponse(accessToken: string, refreshToken: string): Observable<void> {
    return this.authStoreService.updateAuthTokens(accessToken, refreshToken).pipe(
      switchMap(() => this.authProxyService.getUserProfile()),
      switchMap(userProfile => this.authStoreService.updateUserData(userProfile)),
      map(() => null),
    );
  }
}
