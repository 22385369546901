import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfigurationService } from 'crmcloud-core';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TranslationsLoader implements TranslateLoader {
  constructor(
    private readonly http: HttpClient,
    private readonly appConfigurationService: AppConfigurationService,
  ) {}

  public getTranslation(lang: string): Observable<any> {
    const translations$ = new TranslateHttpLoader(this.http, './assets/i18n/', '.json').getTranslation(lang);
    const apiUrl = `${this.appConfigurationService.configuration.api_url}/api/translations/v1/dictionaries/${lang}`;
    const apiTranslations$ = this.http.get(apiUrl, {
      headers: { 'X-Skip-Auth': 'true' },
    });
    return forkJoin([translations$, apiTranslations$]).pipe(
      map(([responseTranslations, responseApi]) => {
        const updatedTranslations = { ...responseTranslations };

        if (updatedTranslations['APPLICATION'] && responseApi['APPLICATION']) {
          updatedTranslations['APPLICATION'] = {
            ...updatedTranslations['APPLICATION'],
            ...responseApi['APPLICATION'],
          };
        }

        if (responseApi['API']) {
          updatedTranslations['API'] = {
            ...responseApi['API'],
          };
        }

        return updatedTranslations;
      }),
    );
  }
}
