/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lang-selector.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/common";
import * as i5 from "crmcloud-core";
import * as i6 from "./lang-selector.component";
var styles_LangSelectorComponent = [i0.styles];
var RenderType_LangSelectorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LangSelectorComponent, data: {} });
export { RenderType_LangSelectorComponent as RenderType_LangSelectorComponent };
function View_LangSelectorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", ""])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("APPLICATION.language")); _ck(_v, 1, 0, currVal_0); }); }
function View_LangSelectorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "pl-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 3).transform(i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), _co.selectedLanguage.key, "APPLICATION.LANGUAGES")))); _ck(_v, 1, 0, currVal_0); }); }
function View_LangSelectorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "button", [["class", "menu-nav-language-switcher__menu-icon dropdown-item"], ["ngbDropdownItem", ""], ["type", "button"]], [[2, "disabled", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeLanguage(_v.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, [[4, 4]], 0, i3.NgbDropdownItem, [i1.ElementRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 3, "i", [["class", "menu-nav-language-switcher__menu-icon"]], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(5, 3), (_l()(), i1.ɵeld(6, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 2), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var currVal_1 = "menu-nav-language-switcher__menu-icon"; var currVal_2 = _ck(_v, 5, 0, "flag-icon", "flag-icon-item", ("flag-icon-" + _v.context.$implicit.key)); _ck(_v, 4, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).disabled; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 7, 0, i1.ɵnov(_v, 9).transform(i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.key, "APPLICATION.LANGUAGES")))); _ck(_v, 7, 0, currVal_3); }); }
export function View_LangSelectorComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i5.EnumTranslateKeyPipe, []), (_l()(), i1.ɵeld(1, 0, null, null, 21, "div", [["class", "menu-nav-language-switcher__container"], ["ngbDropdown", ""]], [[2, "show", null]], null, null, null, null)), i1.ɵdid(2, 1720320, null, 3, i3.NgbDropdown, [i1.ChangeDetectorRef, i3.NgbDropdownConfig, i4.DOCUMENT, i1.NgZone, i1.ElementRef, i1.Renderer2, [2, i3.NgbNavbar]], null, null), i1.ɵqud(603979776, 1, { _menu: 0 }), i1.ɵqud(603979776, 2, { _menuElement: 0 }), i1.ɵqud(603979776, 3, { _anchor: 0 }), (_l()(), i1.ɵeld(6, 0, null, null, 11, "button", [["aria-haspopup", "true"], ["class", "menu-nav-language-switcher__btn btn d-flex align-items-center dropdown-toggle"], ["id", "menuNavLanguageDropDown"], ["ngbDropdownToggle", ""], ["type", "button"]], [[1, "aria-expanded", 0]], [[null, "click"], [null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).dropdown.toggle() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowUp" === en)) {
        var pd_1 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.Home" === en)) {
        var pd_3 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.End" === en)) {
        var pd_4 = (i1.ɵnov(_v, 7).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 16384, null, 0, i3.NgbDropdownToggle, [i3.NgbDropdown, i1.ElementRef], null, null), i1.ɵprd(2048, [[3, 4]], i3.NgbDropdownAnchor, null, [i3.NgbDropdownToggle]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectorComponent_1)), i1.ɵdid(10, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "icon-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 3, "i", [], null, null, null, null, null)), i1.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpad(15, 2), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectorComponent_2)), i1.ɵdid(17, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(18, 0, [[2, 0]], null, 4, "div", [["aria-labelledby", "menuNavLanguageDropDown"], ["class", "menu-nav-language-switcher__dropdown-menu"], ["ngbDropdownMenu", ""]], [[2, "dropdown-menu", null], [2, "show", null], [1, "x-placement", 0]], [[null, "keydown.ArrowUp"], [null, "keydown.ArrowDown"], [null, "keydown.Home"], [null, "keydown.End"], [null, "keydown.Enter"], [null, "keydown.Space"]], function (_v, en, $event) { var ad = true; if (("keydown.ArrowUp" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.ArrowDown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.Home" === en)) {
        var pd_2 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.End" === en)) {
        var pd_3 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_3 && ad);
    } if (("keydown.Enter" === en)) {
        var pd_4 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_4 && ad);
    } if (("keydown.Space" === en)) {
        var pd_5 = (i1.ɵnov(_v, 19).dropdown.onKeyDown($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(19, 16384, [[1, 4]], 1, i3.NgbDropdownMenu, [i3.NgbDropdown], null, null), i1.ɵqud(603979776, 4, { menuItems: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LangSelectorComponent_3)), i1.ɵdid(22, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.displaySelectedLang; _ck(_v, 10, 0, currVal_2); var currVal_3 = _ck(_v, 15, 0, "flag-icon", ("flag-icon-" + _co.selectedLanguage.key)); _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.displaySelectedLang; _ck(_v, 17, 0, currVal_4); var currVal_8 = _co.languages; var currVal_9 = _co.trackByFn; _ck(_v, 22, 0, currVal_8, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).isOpen(); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 7).dropdown.isOpen(); _ck(_v, 6, 0, currVal_1); var currVal_5 = true; var currVal_6 = i1.ɵnov(_v, 19).dropdown.isOpen(); var currVal_7 = i1.ɵnov(_v, 19).placement; _ck(_v, 18, 0, currVal_5, currVal_6, currVal_7); }); }
export function View_LangSelectorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lang-selector", [], null, null, null, View_LangSelectorComponent_0, RenderType_LangSelectorComponent)), i1.ɵdid(1, 114688, null, 0, i6.LangSelectorComponent, [i5.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LangSelectorComponentNgFactory = i1.ɵccf("app-lang-selector", i6.LangSelectorComponent, View_LangSelectorComponent_Host_0, { displaySelectedLang: "displaySelectedLang" }, {}, []);
export { LangSelectorComponentNgFactory as LangSelectorComponentNgFactory };
