import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppConfigurationService } from 'crmcloud-core';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "crmcloud-core";
var TranslationsLoader = /** @class */ (function () {
    function TranslationsLoader(http, appConfigurationService) {
        this.http = http;
        this.appConfigurationService = appConfigurationService;
    }
    TranslationsLoader.prototype.getTranslation = function (lang) {
        var translations$ = new TranslateHttpLoader(this.http, './assets/i18n/', '.json').getTranslation(lang);
        var apiUrl = this.appConfigurationService.configuration.api_url + "/api/translations/v1/dictionaries/" + lang;
        var apiTranslations$ = this.http.get(apiUrl, {
            headers: { 'X-Skip-Auth': 'true' },
        });
        return forkJoin([translations$, apiTranslations$]).pipe(map(function (_a) {
            var responseTranslations = _a[0], responseApi = _a[1];
            var updatedTranslations = tslib_1.__assign({}, responseTranslations);
            if (updatedTranslations['APPLICATION'] && responseApi['APPLICATION']) {
                updatedTranslations['APPLICATION'] = tslib_1.__assign({}, updatedTranslations['APPLICATION'], responseApi['APPLICATION']);
            }
            if (responseApi['API']) {
                updatedTranslations['API'] = tslib_1.__assign({}, responseApi['API']);
            }
            return updatedTranslations;
        }));
    };
    TranslationsLoader.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TranslationsLoader_Factory() { return new TranslationsLoader(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.AppConfigurationService)); }, token: TranslationsLoader, providedIn: "root" });
    return TranslationsLoader;
}());
export { TranslationsLoader };
