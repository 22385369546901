import { HttpParams } from '@angular/common/http';
import { FilterRequest, OrderingRequest, RequestListGridify } from '@crm-portal/core/common/request-list-gridify';

export abstract class BaseProxyService {
  protected prepareQueryGridifyParams(requestList: RequestListGridify<FilterRequest, OrderingRequest>): HttpParams {
    let params = new HttpParams();
    if (requestList == null) {
      return params;
    }

    params = this.preparePaginationQueryParams(params, requestList.page, requestList.pageSize);
    params = this.prepareOrderingQueryParams(params, requestList.orderBy);
    params = this.prepareFilterByQueryParams(params, requestList.filter);
    return params;
  }

  private prepareFilterByQueryParams(httpParams: HttpParams, filterList?: FilterRequest[]): HttpParams {
    if (!filterList) {
      return httpParams;
    }

    let filterBy = '';
    filterList.forEach(item => {
      let value = item.value;
      if (filterBy.length > 0) {
        filterBy += ',';
      }

      if (value === null) {
        filterBy += `${item.field}${item.condition}`;
        return;
      }

      if (typeof value === 'string') {
        value = value.replace(/([(),|\\]|\/i)/g, '\\$1');
      }

      filterBy += `${item.field}${item.condition}${value}`;
    });

    let params = httpParams;
    if (filterBy.length > 0) {
      params = params.set('FilterBy', filterBy);
    }

    return params;
  }

  private preparePaginationQueryParams(httpParams: HttpParams, page?: number, pageSize?: number): HttpParams {
    let params = httpParams;
    if (page != null) {
      params = params.set('page', String(page));
    }
    if (pageSize != null) {
      params = params.set('pageSize', String(pageSize));
    }

    return params;
  }

  private prepareOrderingQueryParams(httpParams: HttpParams, orderBy?: OrderingRequest[]): HttpParams {
    if (!orderBy) {
      return httpParams;
    }

    let orderByParams = '';
    orderBy.forEach(x => {
      const value = x.field;
      if (!value) {
        return;
      }

      if (orderByParams.length > 0) {
        orderByParams += ',';
      }

      orderByParams += `${value} ${x.sortOrder}`;
    });

    let params = httpParams;
    if (orderByParams.length > 0) {
      params = params.set('OrderBy', orderByParams);
    }

    return params;
  }
}
